import React, { PropsWithChildren } from 'react';
import JobsTable from './JobsTable';
import { useRouteLoaderData } from 'react-router-dom';
import { BatchDetailResponse } from '../api';
import { Link, Paper, Stack } from '@mui/material';

import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1)
}));

const Label = styled('label')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary
}));

const Row = ({ children }: { children: React.ReactNode }) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
    {children}
  </Stack>
);

const AttributeDisplay = ({ label, children }: PropsWithChildren<{ label: string }>) => (
  <div style={{ width: '100%' }}>
    <Label>{label}</Label>
    <Item>{children}</Item>
  </div>
);

const BatchById = () => {
  const batch = useRouteLoaderData('batchRoot') as BatchDetailResponse;

  return (
    <div className="batch-by-id">
      <h2>Allocation: {batch.name}</h2>
      <Stack spacing={2}>
        <Row>
          <AttributeDisplay label="Created At">
            {new Date(batch.createdAt).toLocaleString()}
          </AttributeDisplay>
          <div style={{ width: '100%' }}></div>
        </Row>
        <AttributeDisplay label="Description">{batch.description}</AttributeDisplay>
        <Row>
          <AttributeDisplay label="Timetable">{batch.timetable.name}</AttributeDisplay>
          <AttributeDisplay label="Student Choice Collection">
            <Link
              // FIXME: make exporting accessible to UI and remove this hardcoded link
              href={`/admin/student/immutablestudentchoicescollection/${batch.studentChoicesCollection.id}/change/`}
              target="_blank"
            >
              {batch.studentChoicesCollection.name}
            </Link>
          </AttributeDisplay>
        </Row>
        <h4>Metrics</h4>
        <Row>
          <AttributeDisplay label="Student Count">{batch.metrics.studentCount}</AttributeDisplay>
          <AttributeDisplay label="Chemistry/Physics Student Count">
            {batch.metrics.studentTakingChemistryPhysicsCount}
          </AttributeDisplay>
        </Row>
        <h4>Jobs</h4>
        <JobsTable batchId={batch.id} jobs={batch.jobs} />
      </Stack>
    </div>
  );
};

export default BatchById;
