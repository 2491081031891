import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';

interface IncompatibleChoicesTableProps {
  rows: {
    student: string;
    subjects?: string[];
  }[];
}

const RenderSubjects = (props: GridRenderCellParams<any, string[]>) => {
  return (
    <div className="vertical-container" style={{ padding: '5px 0' }}>
      {props.value?.map((subject) => <span key={subject}>{subject}</span>) ?? 'Not Found'}
    </div>
  );
};

const IncompatibleTable: React.FC<IncompatibleChoicesTableProps> = ({
  rows
}: IncompatibleChoicesTableProps) => {
  const columns = [
    {
      field: 'student',
      headerName: 'Student ID',
      minWidth: 150,
      flex: 0
    },
    {
      field: 'subjects',
      headerName: 'Subjects',
      minWidth: 100,
      flex: 0,
      renderCell: RenderSubjects
    }
  ];

  return (
    <DataGrid
      style={{ width: '100%' }}
      getRowId={(row) => row.student}
      getRowHeight={() => 'auto'}
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { pageSize: 5 }
        },
        sorting: {
          sortModel: [{ field: 'student', sort: 'asc' }]
        }
      }}
      pageSizeOptions={[5, 25, 50]}
      disableRowSelectionOnClick
    />
  );
};

export default IncompatibleTable;
