import React from 'react';
import './App.css';
import { BatchDetailResponse, JobDetailResponse, get } from './api';
import { Link, Outlet, RouterProvider, createHashRouter, useMatches } from 'react-router-dom';

import { CssBaseline, Container } from '@mui/material';

import StudentChoices from './student_choices';
import Timetable from './timetable';
import AllocationLayout from './allocation/Layout';
import Import from './bulkChoices';
import Nav from './Nav';
import PermissionedRoute from './routes/PermissionedRoute';
import Allocation from './allocation';
import BatchById from './allocation/BatchById';
import JobById from './allocation/JobById';
import { PERMISSION_BY_PATH } from './permission';

type MatchHandle = {
  crumb: (data: any) => React.ReactNode;
};

export type Match = ReturnType<typeof useMatches>[0] & {
  handle: MatchHandle | undefined;
};

export interface User {
  permissions: string[];
}

const createApiLoader =
  (resource: RequestInfo | URL) =>
  async ({ request }: { request: Request }) => {
    return get(resource, { signal: request.signal });
  };

const App: React.FC = () => {
  // FIXME: use browser router over the hash router
  const router = createHashRouter([
    {
      id: 'root',
      path: '/',
      loader: createApiLoader('/api/v1alpha1/users/current'),
      element: (
        <div className="root">
          <Nav />
          <Container sx={{ py: 4 }}>
            <Outlet />
          </Container>
        </div>
      ),
      children: [
        {
          id: 'studentChoices',
          path: '/',
          element: (
            <PermissionedRoute requiredPermissions={PERMISSION_BY_PATH.studentChoices}>
              <StudentChoices />
            </PermissionedRoute>
          )
        },
        {
          id: 'allocationRoot',
          path: 'allocation',
          element: (
            <PermissionedRoute requiredPermissions={PERMISSION_BY_PATH.allocation}>
              <AllocationLayout />
            </PermissionedRoute>
          ),
          handle: {
            crumb: () => <Link to="/allocation">Allocation Batches</Link>
          },
          children: [
            {
              id: 'allocation',
              path: '',
              element: <Allocation />
            },
            {
              id: 'batchRoot',
              path: ':batchId',
              loader: ({ params, ...rest }) =>
                createApiLoader(`/api/v1alpha1/batches/${params.batchId}`)(rest),
              handle: {
                crumb: (data: BatchDetailResponse) => (
                  <Link to={`/allocation/${data.id}`}>{data.name}</Link>
                )
              },
              children: [
                {
                  id: 'batch',
                  path: '',
                  element: <BatchById />
                },
                {
                  id: 'job',
                  path: 'jobs/:jobId',
                  element: <JobById />,
                  loader: ({ params, ...rest }) =>
                    createApiLoader(`/api/v1alpha1/jobs/${params.jobId}`)(rest),
                  handle: {
                    crumb: (data: JobDetailResponse) => (
                      <Link to={`/allocation/${data.batchId}/jobs/${data.id}`}>Job {data.id}</Link>
                    )
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'bulkChoices',
          element: (
            <PermissionedRoute requiredPermissions={PERMISSION_BY_PATH.bulkChoices}>
              <Import />
            </PermissionedRoute>
          )
        },
        {
          path: 'timetable',
          element: (
            <PermissionedRoute requiredPermissions={PERMISSION_BY_PATH.timetable}>
              <Timetable />
            </PermissionedRoute>
          )
        }
      ]
    }
  ]);

  return (
    <div className="app">
      <CssBaseline />
      {/* FIXME: add global spinner and global error page */}
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
