import { Breadcrumbs } from '@mui/material';
import React from 'react';
import { Outlet, useMatches } from 'react-router-dom';
import { Match } from '../App';

const Layout = () => {
  let matches = useMatches() as Match[];
  let crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => match.handle?.crumb(match.data));

  return (
    <div className="allocation-layout">
      <Breadcrumbs aria-label="breadcrumb">
        {crumbs.map((crumb, index) => (
          <div key={index}>{crumb} </div>
        ))}
      </Breadcrumbs>
      <Outlet />
    </div>
  );
};

export default Layout;
