import React from 'react';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { BatchDetailResponse } from '../api';

type JobsTableProps = {
  batchId: number;
  jobs: BatchDetailResponse['jobs'];
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'Job ID', type: 'number' },
  {
    field: 'allocatedStudentCount',
    headerName: 'Allocated Count',
    width: 120,
    type: 'number'
  },
  {
    field: 'studentWithIncompatibleChoicesCount',
    headerName: 'Incompatible Choices Count',
    width: 120,
    type: 'number'
  },
  {
    field: 'overAllocatedPracticalCount',
    headerName: 'Over Allocated Practical Count',
    width: 120,
    type: 'number'
  },
  {
    field: 'overAllocationSummed',
    headerName: 'Over Allocation Summed',
    width: 120,
    type: 'number'
  },
  {
    field: 'permittedClashCount',
    headerName: 'Clash Count',
    width: 120,
    type: 'number'
  },
  {
    field: 'chemistryPhysicsWeeklyAlternationCount',
    headerName: 'Chem/Phys Weekly Alternation Count',
    width: 150,
    type: 'number'
  },
  {
    field: 'chemistryPhysicsPerfectAlternationCount',
    headerName: 'Chem/Phys Perfect Alternation Count',
    width: 150,
    type: 'number'
  }
];

const JobsTable: React.FC<JobsTableProps> = ({ batchId, jobs }) => {
  const navigate = useNavigate();

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/allocation/${batchId}/jobs/${params.id}`);
  };

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'normal',
            lineHeight: 'normal'
          }
        }}
        rows={jobs}
        columns={columns}
        onRowClick={handleRowClick}
        getRowClassName={() => 'clickable'}
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
      />
    </div>
  );
};

export default JobsTable;
