import React from 'react';
import { InputLabel, Stack, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm, SubmitHandler } from 'react-hook-form';
import AllocatorUIModal, { modalStore, ModalTypes } from '../AllocatorUIModal';
import { createMuiRegister, useGenericSubmit } from '../forms';

const readFile = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target?.result as string);
    };

    reader.onerror = (err) => {
      reject(err);
    };

    reader.readAsText(file);
  });
};

// Duplication between fields and fields names
type Fields = {
  name: string;
  description: string;
  timetableUpload: FileList;
  practicalUpload: FileList;
};

const FIELD_NAMES = ['name', 'description', 'timetableUpload', 'practicalUpload'];

export const Timetable = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError
  } = useForm<Fields>();
  const { setModal } = modalStore();

  const muiRegister = createMuiRegister<Fields>(register, errors);

  const onSuccess = () => {
    setModal('Import successful', ModalTypes.MESSAGE);
    reset();
  };

  const onError = (message: string) => {
    setModal(message, ModalTypes.ALERT);
  };

  const [isSubmitting, onGenericSubmit] = useGenericSubmit(
    '/api/v1alpha1/timetables/',
    FIELD_NAMES,
    setError,
    onSuccess,
    onError
  );

  const onSubmit: SubmitHandler<Fields> = async (data) => {
    let timetableUpload, practicalsUpload;

    try {
      [timetableUpload, practicalsUpload] = await Promise.all<string>([
        readFile(data.timetableUpload[0]),
        readFile(data.practicalUpload[0])
      ]);
    } catch (error) {
      setModal('Failed to load files.', ModalTypes.ALERT);
    }

    onGenericSubmit({
      name: data.name,
      description: data.description,
      timetable_upload: timetableUpload,
      practicals_upload: practicalsUpload
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={2}>
        <Stack>
          <InputLabel>Name</InputLabel>
          <TextField {...muiRegister('name')} />
        </Stack>
        <Stack>
          <InputLabel>Description</InputLabel>
          <TextField {...muiRegister('description')} fullWidth multiline rows={3} />
        </Stack>
        <Stack>
          <InputLabel>Timetable (.yaml)</InputLabel>
          <TextField {...muiRegister('timetableUpload')} type="file" />
        </Stack>
        <Stack>
          <InputLabel>Practicals (.csv)</InputLabel>
          <TextField {...muiRegister('practicalUpload')} type="file" />
        </Stack>
        <div>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Import
          </LoadingButton>
        </div>
        <AllocatorUIModal />
      </Stack>
    </form>
  );
};

export default Timetable;
