import { useState } from 'react';
import { FieldValues, UseFormRegister, FieldErrors, UseFormSetError, Path } from 'react-hook-form';
import apiFetch from '../api';
import { FormSelect } from './FormSelect';

/**
 * Wraps react-hook-form register function to inject MUI specific props.
 *
 * @param register react-hook-form register function
 * @param errors react-hook-form field errors
 * @returns props for MUI input component specifically including error and helperText prop
 */
const createMuiRegister = <TFieldValues extends FieldValues>(
  register: UseFormRegister<TFieldValues>,
  errors: FieldErrors<TFieldValues>
) => {
  return (...args: Parameters<typeof register>) => {
    const props = register(...args);
    const fieldError = errors[props.name];

    return {
      ...props,
      error: !!fieldError,
      helperText: fieldError?.message
    };
  };
};

/**
 * Creates a basic on submit function with basic unsuccessful response handling including setting
 * specific field errors
 *
 * @param endpoint endpoint to post submit data
 * @param fieldNames fields name of the form. Must match the provided type.
 * @param setError react-hook-form setError function
 * @param onSuccess callback for successfully submission
 * @param onError callback for unsuccessfully submission. Used when an Error occurs or for non
 * field related errors in unsuccessful requests.
 * @returns generic on submit function with basic unsuccessful response handling including setting
 * specific field errors.
 */
const useGenericSubmit = <TFieldValues extends FieldValues>(
  endpoint: string,
  fieldNames: string[],
  setError: UseFormSetError<TFieldValues>,
  onSuccess: () => void | undefined,
  onError: (message: string) => void
): [boolean, (data: object) => Promise<void>] => {
  // react-hook-form does include a isSubmitting state however is did not plate nicely with MUI
  // components
  const [isSubmitting, setIsLoading] = useState(false);

  const onSubmit = async (data: object) => {
    setIsLoading(true);

    try {
      const response = await apiFetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        let content = await response.json();

        // iterate through response key
        for (const key of Object.keys(content)) {
          if (fieldNames.includes(key)) {
            // set the error state on the matching field
            setError(key as Path<TFieldValues>, { type: 'api', message: content[key] });
            // remove from content to leave all messages not assignable to a field
            delete content[key];
          }
        }

        // any remaining content from the response will be display in a modal
        if (Object.keys(content).length !== 0) {
          onError(JSON.stringify(content));
        }
      } else {
        onSuccess();
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return [isSubmitting, onSubmit];
};

export { useGenericSubmit, createMuiRegister, FormSelect };
