import React, { useEffect, useState } from 'react';
import StudentSelection from './StudentSelection';
import SubjectSelection from './SubjectSelection';
import Button from '@mui/material/Button';
import { Box, Stack } from '@mui/material';
import Cookies from 'js-cookie';
import { selectedSubjectsStore, studentSelectionStore } from './store';
import { apiFetch } from '../api';
import AllocatorUIModal, { modalStore, ModalTypes } from '../AllocatorUIModal';

const headers: HeadersInit = {
  'Content-Type': 'application/json',
  'X-CSRFToken': Cookies.get('csrftoken') || ''
};

export const StudentChoices = () => {
  const { selectedSubjects, clearSelectedSubjects } = selectedSubjectsStore();
  const { crsid, setCrsid, setName, setCollege, setCrsidConfirmed, crsidConfirmed } =
    studentSelectionStore();
  const { setModal } = modalStore();
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);

  useEffect(() => {
    if (selectedSubjects.length > 0 && crsidConfirmed) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [selectedSubjects, crsidConfirmed]);

  const handleSubmit = () => {
    apiFetch('/api/v1alpha1/studentchoices/', {
      method: 'POST',
      body: JSON.stringify({ subjects: selectedSubjects, crsid: crsid }),
      headers: headers
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            throw new Error(data?.subjects?.[0] || data?.[0]);
          });
        }
        return res.json();
      })
      .then((data) => {
        clearSelectedSubjects();
        setCrsid('');
        setName('');
        setCollege('');
        setCrsidConfirmed(false);
        setModal('Your submission was successful', ModalTypes.MESSAGE);
      })
      .catch((error) => {
        setModal(
          `Submission failed: ${error.message}. Please check your inputs and try again.`,
          ModalTypes.ALERT
        );
      });
  };

  return (
    <>
      <Stack direction="column" spacing={2}>
        <StudentSelection />
        <SubjectSelection />
        <Box>
          <Button variant="contained" onClick={handleSubmit} disabled={isSubmitDisabled}>
            Submit
          </Button>
        </Box>
        <AllocatorUIModal />
      </Stack>
    </>
  );
};

export default StudentChoices;
