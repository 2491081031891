import React, { useState } from 'react';

import { TextField, Box, Stack, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LoadingButton from '@mui/lab/LoadingButton';
import { studentSelectionStore } from './store';
import { apiFetch } from '../api';
import { modalStore, ModalTypes } from '../AllocatorUIModal';

export default function StudentSelection({ initialCrsid = '' }: { initialCrsid?: string }) {
  const {
    crsid,
    name,
    college,
    setCrsid,
    setName,
    setCollege,
    setCrsidConfirmed,
    crsidConfirmed
  } = studentSelectionStore();

  const [crsidInvalid, setCrsidInvalid] = useState(false);
  const [fetching, setFetching] = useState(false);

  const { setModal } = modalStore();

  const handleLookup = async (crsid: string) => {
    setFetching(true);
    try {
      const response = await apiFetch(`/api/v1alpha1/students/${crsid}`, {
        method: 'GET'
      });

      if (!response.ok) {
        if (response.status === 404) {
          setCrsidInvalid(true);
          return;
        }

        throw new Error('Network response was not OK: ' + response.status);
      }

      const data = await response.json();

      setName(data.name);
      setCollege(data.institutionName);

      setCrsidConfirmed(true);
    } catch (error) {
      setModal(
        'An error occurred during lookup: ' + (error as Error).toString(),
        ModalTypes.ALERT
      );
    } finally {
      setFetching(false);
    }
  };

  const lookupUser = () => {
    handleLookup(crsid);
  };

  return (
    <Box
      component="div"
      sx={{
        '& > :not(style)': { m: 1 }
      }}
    >
      <h3>Student</h3>
      <Stack spacing={2} direction="row">
        <TextField
          required
          error={crsidInvalid}
          helperText={crsidInvalid && 'Could not find user'}
          disabled={fetching}
          label="crsid"
          value={crsid}
          onChange={(e) => {
            setCrsid(e.target.value);
            setCrsidConfirmed(false);
            setCrsidInvalid(false);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              lookupUser();
            }
          }}
        />
        <LoadingButton
          loading={fetching}
          disabled={!crsid}
          variant="contained"
          onClick={lookupUser}
        >
          <span>Lookup</span>
        </LoadingButton>
      </Stack>
      {crsidConfirmed && <StudentInfo name={name} college={college} />}
    </Box>
  );
}

const StudentInfo = ({ name, college }: { name: string; college: string }): JSX.Element => (
  <List>
    <ListItem>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary={name} secondary={college} />
    </ListItem>
  </List>
);
