import { create } from 'zustand';

interface SelectedSubjectsState {
  selectedSubjects: string[];
  clearSelectedSubjects(): void;
}

export const selectedSubjectsStore = create<SelectedSubjectsState>((set) => ({
  selectedSubjects: [],
  clearSelectedSubjects: () => set({ selectedSubjects: [] })
}));

interface StudentSelectionState {
  crsid: string;
  name: string;
  college: string;
  crsidConfirmed: boolean;
  setCrsid: (crsid: string) => void;
  setName: (name: string) => void;
  setCollege: (college: string) => void;
  setCrsidConfirmed: (confirmed: boolean) => void;
}

export const studentSelectionStore = create<StudentSelectionState>((set) => ({
  crsid: '',
  crsidConfirmed: false,
  name: '',
  college: '',
  setCrsid: (crsid) => set({ crsid }),
  setCrsidConfirmed: (confirmed) => set({ crsidConfirmed: confirmed }),
  setName: (name) => set({ name }),
  setCollege: (college) => set({ college })
}));
