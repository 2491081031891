import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import Button from '@mui/material/Button';
import { create } from 'zustand';

export enum ModalTypes {
  ALERT = 'ALERT',
  MESSAGE = 'MESSAGE'
}

interface ModalState {
  setModal: (message: string, modalType: ModalTypes) => void;
  modalType: ModalTypes;
  message: string;
}

export const modalStore = create<ModalState>((set) => ({
  setModal: (message, modalType) => set({ message, modalType }),
  modalType: ModalTypes.MESSAGE,
  message: ''
}));

const AllocatorUIModal = () => {
  const [open, setOpen] = useState(false);
  const { modalType, message } = modalStore();

  const handleClose = () => {
    setOpen(false);
  };

  modalStore.subscribe((state) => {
    if (state.message) {
      setOpen(true);
    }
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          width: 300,
          bgcolor: 'background.paper',
          p: 2,
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <h2 id="modal-title" style={{ color: modalType === ModalTypes.ALERT ? 'red' : 'inherit' }}>
          {modalType === ModalTypes.ALERT ? 'Error' : 'Message'}
        </h2>
        <p id="modal-description">{message}</p>
        <Button onClick={handleClose}>Close</Button>
      </Box>
    </Modal>
  );
};
export default AllocatorUIModal;
