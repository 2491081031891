import { LinearProgress, useTheme } from '@mui/material';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { TimeSlot } from '../api';

interface PracticalsTableProps {
  rows: {
    code: string;
    capacity: number;
    studentCount: number;
  }[];
  loading: boolean;
}

const RenderPercentageFilled = (props: GridRenderCellParams<any, number>) => {
  const theme = useTheme();

  if (props.value == null) return;

  return (
    <div
      style={{
        position: 'relative',
        width: '95%',
        height: '24px',
        margin: '4px 0px',
        border: '1px solid',
        borderRadius: '5px'
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: `${props.value.toFixed(0)}%`,
          height: '100%',
          background: props.value > 100 ? theme.palette.warning.light : theme.palette.grey[300],
          borderRadius: '5px',
          zIndex: -1
        }}
      ></div>
      <span style={{ position: 'absolute', width: '100%', textAlign: 'center' }}>
        {props.value.toFixed(0)}%
      </span>
    </div>
  );
};

const RenderPracticals = (props: GridRenderCellParams<any, TimeSlot[]>) => {
  const theme = useTheme();

  return (
    <div>
      {props.value?.map((slot, index) => (
        <div className="muted" key={index}>
          {`${slot.week} ${slot.day}`}{' '}
          <span style={{ color: theme.palette.grey[600] }}>
            ({`${slot.start}-${slot.finish}`})
          </span>
        </div>
      ))}
    </div>
  );
};

const PracticalsTable: React.FC<PracticalsTableProps> = ({
  rows,
  loading
}: PracticalsTableProps) => {
  const columns = [
    {
      field: 'code',
      headerName: 'Practical',
      minWidth: 150,
      flex: 0
    },
    {
      field: 'timeSlots',
      headerName: 'Time Slots',
      minWidth: 200,
      renderCell: RenderPracticals
    },
    {
      field: 'capacity',
      headerName: 'Capacity',
      minWidth: 150
    },
    {
      field: 'studentCount',
      headerName: 'Student Count',
      minWidth: 150,
      flex: 0
    },
    {
      field: 'percentage',
      headerName: 'Percentage Filled',
      minWidth: 150,
      renderCell: RenderPercentageFilled
    }
  ];

  const enrichedRows = rows.map((row) => ({
    ...row,
    percentage: (row.studentCount / row.capacity) * 100
  }));

  return (
    <DataGrid
      style={{ width: '100%' }}
      getRowId={(row) => row.code}
      getRowHeight={() => 'auto'}
      rows={enrichedRows}
      columns={columns}
      loading={loading}
      slots={{
        loadingOverlay: LinearProgress
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: 'code', sort: 'asc' }]
        }
      }}
      disableRowSelectionOnClick
    />
  );
};

export default PracticalsTable;
