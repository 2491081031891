import { LinearProgress } from '@mui/material';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { TeachingUnit, TimeSlot } from '../api';

interface AllocationTableProps {
  rows: {
    student: string;
    subjects?: string[];
    teachingUnits: TeachingUnit[];
    permittedClashCount: number;
    minimumPermittedClashCount: number;
  }[];
}

const RenderSubjects = (props: GridRenderCellParams<any, string[]>) => {
  return (
    <div className="vertical-container" style={{ padding: '5px 0' }}>
      {props.value?.map((subject) => <span key={subject}>{subject}</span>) ?? 'Not Found'}
    </div>
  );
};

const RenderPracticals = (props: GridRenderCellParams<any, TeachingUnit[]>) => {
  return (
    <div className="horizontal-container">
      {props.value?.map((series, index) => (
        <div className="series-container" key={index}>
          <span>{series.code}</span>
          {series.timeSlots.map((slot: TimeSlot) => (
            <div
              className="muted"
              key={`${series.code}-${slot.week}-${slot.day}-${slot.start}-${slot.finish}`}
            >
              {`${slot.week} ${slot.day} ${slot.start}-${slot.finish}`}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const AllocationTable: React.FC<AllocationTableProps> = ({ rows }: AllocationTableProps) => {
  const columns = [
    {
      field: 'student',
      headerName: 'Student ID',
      minWidth: 150,
      flex: 0
    },
    {
      field: 'subjects',
      headerName: 'Subjects',
      minWidth: 100,
      flex: 0,
      renderCell: RenderSubjects
    },
    {
      field: 'permittedClashCount',
      headerName: 'Clash Ct.',
      minWidth: 80,
      flex: 0
    },
    {
      field: 'minimumPermittedClashCount',
      headerName: 'Min. Clash Ct.',
      minWidth: 100,
      flex: 0
    },
    {
      field: 'teachingUnits',
      headerName: 'Practicals',
      minWidth: 250,
      flex: 1,
      renderCell: RenderPracticals
    }
  ];

  return (
    <DataGrid
      style={{ width: '100%' }}
      getRowId={(row) => row.student}
      getRowHeight={() => 'auto'}
      rows={rows}
      columns={columns}
      slots={{
        loadingOverlay: LinearProgress
      }}
      initialState={{
        pagination: {
          paginationModel: { pageSize: 5 }
        },
        sorting: {
          sortModel: [{ field: 'crsid', sort: 'asc' }]
        }
      }}
      pageSizeOptions={[5, 25, 50]}
      disableRowSelectionOnClick
    />
  );
};

export default AllocationTable;
