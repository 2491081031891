import { isLocalhost } from './serviceWorker';
import Cookies from 'js-cookie';

export function apiFetch(resource: RequestInfo | URL, options: { [index: string]: any }) {
  return fetch(resource, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken') || ''
    },
    ...options
  }).then(async (response) => {
    if (response.status === 403) {
      const json = await response.json();
      if (json.detail.includes('Authentication credentials were not provided')) {
        let targetUrl;
        if (isLocalhost) {
          const targetPath = `${window.location.pathname}${window.location.search}${window.location.hash}`;
          targetUrl = `/admin/login/?next=${encodeURIComponent(targetPath)}`;
        } else {
          targetUrl = '/accounts/login/google-oauth2/';
        }
        window.location.href = targetUrl;
        // Give the browser time to redirect
        await new Promise((resolve, reject) => window.setTimeout(resolve, 1000));
      }
    }
    return response;
  });
}

const get = async <T,>(resource: RequestInfo | URL, options?: RequestInit): Promise<T> => {
  const response = await apiFetch(resource, {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    },
    ...options
  });

  if (!response.ok) {
    throw new Error('HTTP error ' + response.status);
  }

  return response.json();
};

const post = async <T,>(resource: RequestInfo | URL, options?: RequestInit): Promise<T> => {
  const response = await apiFetch(resource, {
    method: 'POST',
    ...options
  });

  if (!response.ok) {
    throw new Error('HTTP error ' + response.status);
  }

  return response.json();
};

interface TimeSlot {
  day: 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT';
  week: 'EVEN' | 'ODD';
  start: string;
  finish: string;
}

interface BatchBaseResponse {
  id: number;
  name: string;
  description: string;
  createdAt: string;
}

interface BatchResponse extends BatchBaseResponse {
  studentChoicesCollectionName: string;
  timetableName: string;
}

interface BatchDetailResponse extends BatchBaseResponse {
  timetable: Timetable;
  studentChoicesCollection: StudentChoicesCollection;
  studentChoices: StudentChoiceResponse[];
  jobs: Array<{
    id: number;
    studentCount: number;
    jobResultId: number | null;
    allocatedStudentCount: number | null;
    permittedClashCount: number | null;
    studentWithIncompatibleChoicesCount: number | null;
    overAllocatedPracticalCount: number | null;
    overAllocationSummed: number | null;
    chemistryPhysicsPerfectAlternationCount: number | null;
    chemistryPhysicsWeeklyAlternationCount: number | null;
  }>;
  metrics: {
    studentCount: number;
    studentTakingChemistryPhysicsCount: number;
  };
}

interface SubjectResponse {
  id: string;
  name: string;
  capacity: number;
  number_allocated: number;
}

interface StudentChoiceResponse {
  crsid: string;
  /** Array of subjects identifiers */
  subjects: string[];
}

interface StudentChoicesCollection {
  id: number;
  name: string;
}

interface Timetable {
  id: string;
  name: string;
}

interface TeachingUnit {
  code: string;
  type: 'PRACTICAL' | 'LECTURE';
  timeSlots: TimeSlot[];
}

interface StudentAllocationMetrics {
  allocated: boolean;
  allocationOptionCount: number;
  permittedClashCount: number;
  minimumPermittedClashCount: number;
}

interface StudentAllocation {
  id: number;
  student: string;
  teachingUnits: TeachingUnit[];
  metrics: StudentAllocationMetrics;
}

interface ResultMetrics {
  allocatedStudentCount: number;
  minimumPermittedClashCount: number;
  overAllocatedPracticalCount: number;
  overAllocationSummed: number;
  permittedClashesCountGrouped: Record<string, number>;
  permittedClashCount: number;
  studentWithIncompatibleChoicesCount: number;
  chemistryPhysicsPerfectAlternationCount: number;
  chemistryPhysicsWeeklyAlternationCount: number;
}

interface Result {
  id: number;
  studentAllocations: StudentAllocation[];
  metrics: ResultMetrics;
}

interface JobDetailResponse {
  id: number;
  batchId: number;
  result: Result | null;
}

interface PracticalAllocation {
  code: string;
  capacity: number;
  timeSlots: TimeSlot[];
  studentCount: number;
}

interface SubjectAllocation {
  name: string;
  code: string;
  practicals: PracticalAllocation[];
}

interface SubjectsResponse {
  subjects: SubjectAllocation[];
  metrics: {
    unevenlyDistributionScore: number;
  };
}

// FIXME: Legacy interface to be removed
interface AllocationResponse {
  success: boolean;
  totalAllocatedStudents: number;
  totalUnallocatedStudents: number;
  unevenlyAllocatedScore: number;
  /** StudentAllocation of students successfully allocated indexed on the student CRSID */
  allocatedStudents: {
    [index: string]: StudentAllocationLegacy;
  };
  /** Updated StudentAllocation of students unsuccessfully allocated indexed on the student CRSID */
  unallocatedStudents: {
    [index: string]: StudentAllocationLegacy;
  };
  /** Object only containing subject ID indexed by Subject ID */
  subjects: {
    [index: string]: SubjectAllocation;
  };
  /** Allocation results in respect to series indexed by Series ID   */
  series: {
    [index: string]: SeriesAllocation;
  };
}

interface SubjectAllocation {
  id: string;
  capacity: number;
  numberAllocated: number;
  unevenlyAllocatedScore: number;
  series: {
    [index: string]: SeriesAllocation;
  };
}

interface SeriesAllocation {
  /** For example "NST1A_CH_06" */
  id: string;
  /** For example "NST1A_CH" */
  subjectId: string;
  capacity: number;
  numberAllocated: number;
  slots: TimeSlot[];
}

interface StudentAllocationStatistics {
  allocationOptionCount: number;
  minRequiredClashes: number;
  maxRequiredClashes: number;
  clashCount: number;
  undesirableCombinationCount: number;
  takingChemistryPhysics: boolean;
  chemistryPhysicsAlternating: boolean;
  chemistryPhysicsOnAlternatingWeeks: boolean;
}

interface StudentAllocationLegacy {
  seriesAllocated: SeriesAllocation[];
  reasonsNotAllocated?: string[];
  statistics?: StudentAllocationStatistics;
}

interface PracticalAllocated {
  timeSlots: TimeSlot[];
}

interface AllocatedStudent {
  choices: string[];
  practicalsAllocated: Record<string, PracticalAllocated>;
}

export default apiFetch;
export { get, post };
export type {
  AllocatedStudent,
  AllocationResponse,
  BatchDetailResponse,
  BatchResponse,
  JobDetailResponse,
  PracticalAllocation,
  PracticalAllocated,
  SeriesAllocation,
  StudentAllocationStatistics,
  StudentChoiceResponse,
  StudentChoicesCollection,
  SubjectResponse,
  SubjectsResponse,
  TeachingUnit,
  Timetable,
  TimeSlot
};
