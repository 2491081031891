import { Card, CardContent, Icon, Stack, Typography } from '@mui/material';

interface AllocationMetricProps {
  label: string;
  value?: number;
  iconColor: string;
  Icon: typeof Icon;
}

const AllocationMetric = (props: AllocationMetricProps) => {
  const { label, value, iconColor, Icon } = props;

  return (
    // width 1152px divided into fives cards with gaps
    <Card sx={{ display: 'flex', justifyContent: 'space-between', width: 217.6, height: 120 }}>
      <CardContent sx={{ pr: 0 }}>
        <Stack sx={{ justifyContent: 'space-between', height: '100%' }}>
          <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
            {label}
          </Typography>
          <Typography variant="h4" component="div">
            {value != null ? value : 'Unknown'}
          </Typography>
        </Stack>
      </CardContent>
      <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Icon sx={{ color: iconColor, fontSize: 52 }} />
      </CardContent>
    </Card>
  );
};

export default AllocationMetric;
