import React, { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export type Props = {
  isAllowed: boolean;
  children: ReactElement;
  redirectPath?: string;
};

const ProtectedRoute = ({
  isAllowed,
  children,
  // FIXME: add a page which doesn't require permissions and redirect to that
  redirectPath = '/'
}: Props) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
