/**
 * Select component for use with react-hook-form forms and MUI
 */

import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller, FieldError, FieldPath, FieldValues } from 'react-hook-form';

type MenuItemProps = {
  value: any;
  label: string;
};

type FormSelectProps<TFieldValues extends FieldValues> = {
  control: any;
  name: FieldPath<TFieldValues>;
  label: string;
  fieldError: FieldError | undefined;
  choices: MenuItemProps[];
};

export const FormSelect = <TFieldValues extends FieldValues>({
  control,
  name,
  label,
  fieldError,
  choices
}: FormSelectProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      // @ts-ignore - select component requires a empty string instead of undefined
      defaultValue=""
      render={({ field }: any) => (
        <FormControl fullWidth error={!!fieldError}>
          <InputLabel>{label}</InputLabel>
          <Select label={label} {...field} required>
            {choices.map((choice) => (
              <MenuItem key={choice.value} value={choice.value}>
                {choice.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{fieldError?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
